import React from "react"
import UIKITheader from '../../components/ui-kit-header'
import Title from '../../components/title'
import Black from "../../components/black"
import *as Styles from "../../assets/css/ui-kit.css"
import Button from "../../components/button-generic"
import Icon from "../../components/icon"
import ButtonGeneric from "../../components/button-generic"
import *as Styles2 from "../../assets/css/global.css"
import *as Styles3 from "../../assets/fonts/icomoon/style.css"
import *as Styles4 from "../../assets/fonts/icomoon/ie7/ie7.css"

const Buttons = (props) => {
    return (
        <div>
            <UIKITheader title="UNIDA Components Overview & Ui Kit"
                subtitle="Visual overview of the Unida Design System styles, components, their states, statuses & sizes."
            />
            <div className="Coloursimages">
                <Title title="Buttons" type={2} color="black" weight={500} />
                <div className="bg-white Rectangle p-5">
                    <Title title="Call to Action (CTA)" type={5} color="bordeaux" weight={300} />
                    <div className="container">
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="Primary" weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                            <div className="col-4 align-left">
                                <Title title="Secondary" weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                            <div className="col align-left">
                                <Title title="Secondary w/icon" weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 align-right">
                                <Title title="Large" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-4 align-right">
                                <Title title="Large " weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-4 align-right">
                                <Title title="Large " weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <Title title="Default" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline button bordeaux my-2 my-sm-0 bg-white button-cursor" title="LOREM IPSUM" />
                            </div>
                            <div className="col-1">
                                <Title title="Default " weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline button-cursor green-dark underline border-white bg-white" title="LOREM IPSUM" />
                            </div>
                            <div className="col">
                                <Title title="Default " weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline bordeaux button-cursor-disabled btn-bordeaux underline border-white bg-white" title="LOREM IPSUM" icon="icons-32-x-32-generic-plus" iconSize={26} color="bordeaux" iconClassName="icon space-button button-cursor" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col-1">
                                <Title title="Hover" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline button my-2 my-sm-0 bg-bordeaux text-white button-cursor" title="LOREM IPSUM" />
                            </div>
                            <div className="col-1">
                                <Title title="Hover " weight={500} color="grey-dark" type={7} className="size-12-line-16 strong" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline grey-dark underline border-white bg-white" title="LOREM IPSUM" />
                            </div>
                            <div className="col">
                                <Title title="Hover " weight={500} color="grey-dark" type={7} className="size-12-line-16 strong" />
                            </div>
                            <div className="col glyph fs1">
                                <ButtonGeneric className="btn-outline grey-dark btn-grey-dark underline border-white bg-white" title="LOREM IPSUM" icon="icons-32-x-32-generic-plus" iconSize={26} color="grey-dark" iconClassName="icon space-button button-cursor" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col-1">
                                <Title title="Disabled" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline button bordeaux my-2 my-sm-0 bg-white" title="LOREM IPSUM" disabled/>
                            </div>
                            <div className="col-1">
                                <Title title="Disabled" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline green-dark underline border-white bg-white" title="LOREM IPSUM" disabled/>
                            </div>
                            <div className="col">
                                <Title title="Disabled" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline bordeaux btn-bordeaux underline border-white bg-white" title="LOREM IPSUM" icon="icons-32-x-32-generic-plus" iconSize={26} iconClassName="icon icon-bordeaux space-button" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space">

                </div>
                <div className="bg-white Rectangle p-5">
                    <Title title="Otros" type={5} color="bordeaux" weight={300} />
                    <div className="bg-white Rectangle">
                        <div className="row">
                            <div className="col-4 align-left">
                                <Title title="Filtros/Tags" weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                            <div className="col-4 align-left">
                                <Title title="Text Links" weight={900} color="black" type={5} className="size-16-line-20 bold" />
                            </div>
                        </div>
                        <div className="row space2">
                            <div className="col-1">
                                <Title title="Default" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-3 ">
                                <ButtonGeneric className="button-filters bg-green text-white size-12-line-16 btn-white " title="Lorem ipsum" icon="icons-32-x-32-export-x" iconSize={24} iconClassName="icon space-button button-cursor" />
                            </div>
                            <div className="col-1">
                                <Title title="Default" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline green-dark underline border-white bg-white size-14-line-17" title="Lorem ipsum" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <Title title="Hover" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col-3 ">
                                <ButtonGeneric className="button-filters bg-green-dark text-white size-12-line-16 btn-white " title="Lorem ipsum" icon="icons-32-x-32-export-x" iconSize={24} iconClassName="icon space-button button-cursor" />
                            </div>
                            <div className="col-1">
                                <Title title="Hover" weight={500} color="grey-dark" type={7} className="size-12-line-16 medium" />
                            </div>
                            <div className="col">
                                <ButtonGeneric className="btn-outline grey-dark underline border-white bg-white size-14-line-17" title="Lorem ipsum" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buttons